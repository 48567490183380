import React, { useCallback } from "react";
import * as S from "./SubscriptionAgreement.style";
import * as CommonStyles from "@styles/CommonStyles.style";
import { useTranslation } from "react-i18next";

interface ISubscriptionAgreementSubItem {
  itemNumber: string;
  title: string;
  text: string;
}

const SubscriptionAgreement: React.FC = () => {
  const { t } = useTranslation();

  const generateSubscriptionAgreementItem = useCallback(
    (
      itemNumber: string,
      header: string,
      subItems: ISubscriptionAgreementSubItem[],
      text?: string
    ) => {
      return (
        <S.CustomContainer $margin="0 0 2rem 0">
          <S.CustomContainer $margin="0 0 1rem 0">
            <CommonStyles.Sn3Title>
              {itemNumber}
              {". "}
              {header}
            </CommonStyles.Sn3Title>
          </S.CustomContainer>

          <CommonStyles.Sn2Text $isLight>{text}</CommonStyles.Sn2Text>

          {subItems.map((item) => {
            return (
              <S.CustomContainer $margin="0 0 1rem 0">
                <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
                  {item.itemNumber} {item.title}
                </CommonStyles.Sn2Text>
                <CommonStyles.Sn2Text $isLight style={{ display: "block" }}>
                  {item.text}
                </CommonStyles.Sn2Text>
              </S.CustomContainer>
            );
          })}
        </S.CustomContainer>
      );
    },
    [t]
  );

  return (
    <S.CustomContainer $margin="1rem 0" $padding="0 0.5rem">
      <S.CustomContainer $margin="0 0 2rem 0">
        <S.CustomContainer $margin="0 0 1rem 0">
          <CommonStyles.Sn1Title>
            {t("contracts.subscriptionAgreement.title")}
          </CommonStyles.Sn1Title>
        </S.CustomContainer>

        <CommonStyles.Sn2Text $isLight>
          {t("contracts.subscriptionAgreement.intro")}
        </CommonStyles.Sn2Text>
      </S.CustomContainer>

      {generateSubscriptionAgreementItem(
        "1",
        t("contracts.subscriptionAgreement.definitions.title"),
        [],
        t("contracts.subscriptionAgreement.definitions.text")
      )}

      {generateSubscriptionAgreementItem(
        "2",
        t("contracts.subscriptionAgreement.productsAndServices.title"),
        [
          {
            itemNumber: "2.1",
            title: t(
              "contracts.subscriptionAgreement.productsAndServices.bullets.customerUnderstansdAndAgrees.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.productsAndServices.bullets.customerUnderstansdAndAgrees.text"
            ),
          },
          {
            itemNumber: "2.2",
            title: t(
              "contracts.subscriptionAgreement.productsAndServices.bullets.infrastructureRequirements.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.productsAndServices.bullets.infrastructureRequirements.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "3",
        t("contracts.subscriptionAgreement.useOfProducts.title"),
        [
          {
            itemNumber: "3.1",
            title: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.sinopsisResponsibilities.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.sinopsisResponsibilities.text"
            ),
          },
          {
            itemNumber: "3.2",
            title: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.customerResponsibilites.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.customerResponsibilites.text"
            ),
          },
          {
            itemNumber: "3.3",
            title: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.restrictions.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.restrictions.text"
            ),
          },
          {
            itemNumber: "3.4",
            title: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.security.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.security.text"
            ),
          },
          {
            itemNumber: "3.5",
            title: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.support.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.useOfProducts.bullets.support.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "4",
        t("contracts.subscriptionAgreement.feesPaymentsTaxes.title"),
        [
          {
            itemNumber: "4.1",
            title: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.fees.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.fees.text"
            ),
          },
          {
            itemNumber: "4.2",
            title: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.invoicingAndPayments.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.invoicingAndPayments.text"
            ),
          },
          {
            itemNumber: "4.3",
            title: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.overduePayments.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.overduePayments.text"
            ),
          },
          {
            itemNumber: "4.4",
            title: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.suspensionOfProductsOrServices.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.suspensionOfProductsOrServices.text"
            ),
          },
          {
            itemNumber: "4.5",
            title: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.renewalSubscriptionTerm.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.renewalSubscriptionTerm.text"
            ),
          },
          {
            itemNumber: "4.6",
            title: "",
            text: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.feesAndTermsOfUse.text"
            ),
          },
          {
            itemNumber: "4.7",
            title: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.taxes.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.feesPaymentsTaxes.bullets.taxes.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "5",
        t("contracts.subscriptionAgreement.intellectualProperty.title"),
        [
          {
            itemNumber: "5.1",
            title: t(
              "contracts.subscriptionAgreement.intellectualProperty.bullets.visudatIntellectualProperty.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.intellectualProperty.bullets.visudatIntellectualProperty.text"
            ),
          },
          {
            itemNumber: "5.2",
            title: t(
              "contracts.subscriptionAgreement.intellectualProperty.bullets.customerData.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.intellectualProperty.bullets.customerData.text"
            ),
          },
          {
            itemNumber: "5.3",
            title: t(
              "contracts.subscriptionAgreement.intellectualProperty.bullets.suggestions.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.intellectualProperty.bullets.suggestions.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "6",
        t("contracts.subscriptionAgreement.confidentiality.title"),
        [
          {
            itemNumber: "6.1",
            title: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.definitionOfConfidentialInformation.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.definitionOfConfidentialInformation.text"
            ),
          },
          {
            itemNumber: "6.2",
            title: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.protection.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.protection.text"
            ),
          },
          {
            itemNumber: "6.3",
            title: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.compelledDisclosure.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.compelledDisclosure.text"
            ),
          },
          {
            itemNumber: "6.3",
            title: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.remedies.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.remedies.text"
            ),
          },
          {
            itemNumber: "6.3",
            title: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.accessToData.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.confidentiality.bullets.accessToData.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "7",
        t("contracts.subscriptionAgreement.indemnification.title"),
        [
          {
            itemNumber: "7.1",
            title: t(
              "contracts.subscriptionAgreement.indemnification.bullets.indemnificationBySinopsis.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.indemnification.bullets.indemnificationBySinopsis.text"
            ),
          },
          {
            itemNumber: "7.2",
            title: t(
              "contracts.subscriptionAgreement.indemnification.bullets.indemnificationByCustomer.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.indemnification.bullets.indemnificationByCustomer.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "8",
        t("contracts.subscriptionAgreement.warrantiesAndDisclaimers.title"),
        [
          {
            itemNumber: "8.1",
            title: t(
              "contracts.subscriptionAgreement.warrantiesAndDisclaimers.bullets.warranties.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.warrantiesAndDisclaimers.bullets.warranties.text"
            ),
          },
          {
            itemNumber: "8.2",
            title: t(
              "contracts.subscriptionAgreement.warrantiesAndDisclaimers.bullets.disclaimer.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.warrantiesAndDisclaimers.bullets.disclaimer.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "9",
        t("contracts.subscriptionAgreement.limitationOfLiability.title"),
        [
          {
            itemNumber: "9.1",
            title: t(
              "contracts.subscriptionAgreement.limitationOfLiability.bullets.limitationOfLiability.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.limitationOfLiability.bullets.limitationOfLiability.text"
            ),
          },
          {
            itemNumber: "9.2",
            title: t(
              "contracts.subscriptionAgreement.limitationOfLiability.bullets.exclusionOfConsequentialAndRelatedDamages.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.limitationOfLiability.bullets.exclusionOfConsequentialAndRelatedDamages.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "10",
        t("contracts.subscriptionAgreement.termAndTermination.title"),
        [
          {
            itemNumber: "10.1",
            title: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.termOfAgreement.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.termOfAgreement.text"
            ),
          },
          {
            itemNumber: "10.2",
            title: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.subscriptionTerm.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.subscriptionTerm.text"
            ),
          },
          {
            itemNumber: "10.3",
            title: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.terminationForCause.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.terminationForCause.text"
            ),
          },
          {
            itemNumber: "10.4",
            title: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.outstandingFees.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.outstandingFees.text"
            ),
          },
          {
            itemNumber: "10.5",
            title: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.effectOfTerminationAndSurvivingProvisions.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.effectOfTerminationAndSurvivingProvisions.text"
            ),
          },
          {
            itemNumber: "10.6",
            title: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.freeAccountTermination.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.termAndTermination.bullets.freeAccountTermination.text"
            ),
          },
        ]
      )}

      {generateSubscriptionAgreementItem(
        "11",
        t("contracts.subscriptionAgreement.generalProvisions.title"),
        [
          {
            itemNumber: "11.1",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.relationshipOfTheParties.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.relationshipOfTheParties.text"
            ),
          },
          {
            itemNumber: "11.2",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.noThirdPartyBeneficiaries.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.noThirdPartyBeneficiaries.text"
            ),
          },
          {
            itemNumber: "11.3",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.notices.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.notices.text"
            ),
          },
          {
            itemNumber: "11.4",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.waiverAndCumulativeRemedies.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.waiverAndCumulativeRemedies.text"
            ),
          },
          {
            itemNumber: "11.5",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.forceMajeure.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.forceMajeure.text"
            ),
          },
          {
            itemNumber: "11.6",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.severability.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.severability.text"
            ),
          },
          {
            itemNumber: "11.7",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.attorneyFees.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.attorneyFees.text"
            ),
          },
          {
            itemNumber: "11.8",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.publicity.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.publicity.text"
            ),
          },
          {
            itemNumber: "11.9",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.excuseFromPerformance.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.excuseFromPerformance.text"
            ),
          },
          {
            itemNumber: "11.10",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.assignment.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.assignment.text"
            ),
          },
          {
            itemNumber: "11.11",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.governingLaw.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.governingLaw.text"
            ),
          },
          {
            itemNumber: "11.12",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.exportCompliance.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.exportCompliance.text"
            ),
          },
          {
            itemNumber: "11.13",
            title: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.entireAgreement.title"
            ),
            text: t(
              "contracts.subscriptionAgreement.generalProvisions.bullets.entireAgreement.text"
            ),
          },
        ]
      )}
    </S.CustomContainer>
  );
};

export default SubscriptionAgreement;
