import React, { useCallback } from "react";
import { useAppSelector } from "@app/common/hooks/reduxHooks";
import * as CommonStyles from "@styles/CommonStyles.style";
import { LicensePricingCard } from "@app/components/LicensePricingCard";
import SectionHeader from "@app/components/SectionHeader/SectionHeader";
import { Col, Row } from "antd";
import * as S from "./Pricing.style";
import { useTranslation } from "react-i18next";

interface IPricingProps {
  id: string;
}

const Pricing: React.FC<IPricingProps> = ({ id }) => {
  const { t } = useTranslation();
  const licenses = useAppSelector((state) => state.license.data);

  const getSortedLicenses = useCallback(() => {
    const temp = [...licenses];
    return temp.sort((l1, l2) => (l1.price.us >= l2.price.us ? 1 : -1));
  }, [licenses]);

  return (
    <CommonStyles.WrapperRow id={id}>
      <CommonStyles.FullyCenteredRow>
        <Col span={24}>
          <SectionHeader
            title={t("pages.main.sections.pricing.title")}
            subtitle={t("pages.main.sections.pricing.subtitle")}
          />
        </Col>

        <Col span={24}>
          <CommonStyles.FullyCenteredRow>
            {getSortedLicenses().map((license) => (
              <Col
                key={license.licenseType}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                xxl={6}
              >
                <Row gutter={[24, 24]} justify="center">
                  <LicensePricingCard license={license} />
                </Row>
              </Col>
            ))}
          </CommonStyles.FullyCenteredRow>
        </Col>
      </CommonStyles.FullyCenteredRow>
    </CommonStyles.WrapperRow>
  );
};

export default Pricing;
