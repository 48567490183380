import React, { useCallback } from "react";
import { Col } from "antd";
import { Direction } from "@common/enums";
import { FeatureItem } from "@components/FeatureItem";
import SectionHeader from "@components/SectionHeader/SectionHeader";
import DatasetScreenshot from "@assets/images/dataset.png";
import OrganizationScreenshot from "@assets/images/organization.png";
import MeasurementScreenshot from "@assets/images/measurement.png";
import DashboardScreenshot from "@assets/images/dashboard.png";
import DatasetShareScreenshot from "@assets/images/dataset_share.png";
import {
  AppstoreOutlined,
  BookOutlined,
  CodeOutlined,
  DatabaseOutlined,
  EyeOutlined,
  HighlightOutlined,
  PieChartOutlined,
  ProjectOutlined,
  ShareAltOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { FeatureItemType } from "@app/common/types";
import * as CommonStyles from "@styles/CommonStyles.style";
import { useTranslation } from "react-i18next";
import { useLanguage } from "@app/common/hooks/useLanguage";

interface IFeaturesProps {
  id: string;
}

const Features: React.FC<IFeaturesProps> = ({ id }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const getFeatureItems = useCallback((): FeatureItemType[] => {
    return [
      {
        title: t(
          "pages.main.sections.features.items.organizationWorkspace.title"
        ),
        description: t(
          "pages.main.sections.features.items.organizationWorkspace.description"
        ),
        imageDirection: Direction.RIGHT,
        imageSource: OrganizationScreenshot,
        bullets: [
          {
            icon: <TeamOutlined />,
            title: t(
              "pages.main.sections.features.items.organizationWorkspace.subItems.organizationMembers.title"
            ),
            text: t(
              "pages.main.sections.features.items.organizationWorkspace.subItems.organizationMembers.description"
            ),
          },
          {
            icon: <DatabaseOutlined />,
            title: t(
              "pages.main.sections.features.items.organizationWorkspace.subItems.organizationData.title"
            ),
            text: t(
              "pages.main.sections.features.items.organizationWorkspace.subItems.organizationData.description"
            ),
          },
        ],
      },
      {
        title: t("pages.main.sections.features.items.datasets.title"),
        description: t(
          "pages.main.sections.features.items.datasets.description"
        ),
        imageDirection: Direction.LEFT,
        imageSource: DatasetScreenshot,
        bullets: [
          {
            icon: <BookOutlined />,
            title: t(
              "pages.main.sections.features.items.datasets.subItems.createFromTemplate.title"
            ),
            text: t(
              "pages.main.sections.features.items.datasets.subItems.createFromTemplate.description"
            ),
          },
          {
            icon: <ProjectOutlined />,
            title: t(
              "pages.main.sections.features.items.datasets.subItems.createKpis.title"
            ),
            text: t(
              "pages.main.sections.features.items.datasets.subItems.createKpis.description"
            ),
          },
        ],
      },
      {
        title: t(
          "pages.main.sections.features.items.measurementRecording.title"
        ),
        description: t(
          "pages.main.sections.features.items.measurementRecording.description"
        ),
        imageDirection: Direction.RIGHT,
        imageSource: MeasurementScreenshot,
        bullets: [
          {
            icon: <CodeOutlined />,
            title: t(
              "pages.main.sections.features.items.measurementRecording.subItems.measurementInputs.title"
            ),
            text: t(
              "pages.main.sections.features.items.measurementRecording.subItems.measurementInputs.description"
            ),
          },
          {
            icon: <HighlightOutlined />,
            title: t(
              "pages.main.sections.features.items.measurementRecording.subItems.measurementEdit.title"
            ),
            text: t(
              "pages.main.sections.features.items.measurementRecording.subItems.measurementEdit.description"
            ),
          },
        ],
      },
      {
        title: t("pages.main.sections.features.items.kpiDashboards.title"),
        description: t(
          "pages.main.sections.features.items.kpiDashboards.description"
        ),
        imageDirection: Direction.LEFT,
        imageSource: DashboardScreenshot,
        bullets: [
          {
            icon: <PieChartOutlined />,
            title: t(
              "pages.main.sections.features.items.kpiDashboards.subItems.visualizations.title"
            ),
            text: t(
              "pages.main.sections.features.items.kpiDashboards.subItems.visualizations.description"
            ),
          },
          {
            icon: <AppstoreOutlined />,
            title: t(
              "pages.main.sections.features.items.kpiDashboards.subItems.dashboards.title"
            ),
            text: t(
              "pages.main.sections.features.items.kpiDashboards.subItems.dashboards.description"
            ),
          },
        ],
      },
      {
        title: t(
          "pages.main.sections.features.items.collaborateWithOrganizationMembers.title"
        ),
        description: t(
          "pages.main.sections.features.items.collaborateWithOrganizationMembers.description"
        ),
        imageDirection: Direction.RIGHT,
        imageSource: DatasetShareScreenshot,
        bullets: [
          {
            icon: <ShareAltOutlined />,
            title: t(
              "pages.main.sections.features.items.collaborateWithOrganizationMembers.subItems.shareData.title"
            ),
            text: t(
              "pages.main.sections.features.items.collaborateWithOrganizationMembers.subItems.shareData.description"
            ),
          },
          {
            icon: <EyeOutlined />,
            title: t(
              "pages.main.sections.features.items.collaborateWithOrganizationMembers.subItems.representInsights.title"
            ),
            text: t(
              "pages.main.sections.features.items.collaborateWithOrganizationMembers.subItems.representInsights.description"
            ),
          },
        ],
      },
    ];
  }, [t, language]);

  return (
    <CommonStyles.WrapperRow id={id}>
      <Col span={24}>
        <CommonStyles.FullyCenteredRow>
          <SectionHeader
            title={t("pages.main.sections.features.title")}
            subtitle={t("pages.main.sections.features.subtitle")}
          />
        </CommonStyles.FullyCenteredRow>

        <CommonStyles.FullyCenteredRow>
          {getFeatureItems().map((item: FeatureItemType) => (
            <CommonStyles.FullyCenteredRow>
              <FeatureItem item={item} />
            </CommonStyles.FullyCenteredRow>
          ))}
        </CommonStyles.FullyCenteredRow>
      </Col>
    </CommonStyles.WrapperRow>
  );
};

export default Features;
