import React from "react";
import { Button, Col } from "antd";
import devices from "@assets/images/devices.png";
import { useResponsive } from "@app/common/hooks/useResponsive";
import * as S from "./Home.style";
import * as CommonStyles from "@styles/CommonStyles.style";
import { useTranslation } from "react-i18next";

interface IHomeProps {
  id: string;
}

const Home: React.FC<IHomeProps> = ({ id }) => {
  const { t } = useTranslation();
  const { mobileOnly } = useResponsive();

  return (
    <CommonStyles.WrapperRow id={id}>
      <CommonStyles.FullyCenteredRow>
        <Col>
          <CommonStyles.FullyCenteredRow>
            <CommonStyles.Sn1Title>
              {t("pages.main.sections.home.visualizeData")}
            </CommonStyles.Sn1Title>
          </CommonStyles.FullyCenteredRow>

          <CommonStyles.FullyCenteredRow>
            <CommonStyles.Sn1Text $isLight>
              {t("pages.main.sections.home.visualizeDataExplanation")}
            </CommonStyles.Sn1Text>
          </CommonStyles.FullyCenteredRow>

          <CommonStyles.FullyCenteredRow>
            <Button
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_APPLICATION_PATH}`,
                  "_blank"
                );
              }}
              type="primary"
              size={mobileOnly ? "small" : "middle"}
            >
              {t("pages.main.sections.home.getStarted")}
            </Button>
          </CommonStyles.FullyCenteredRow>

          <CommonStyles.FullyCenteredRow>
            <S.DevicesImage src={devices} />
          </CommonStyles.FullyCenteredRow>
        </Col>
      </CommonStyles.FullyCenteredRow>
    </CommonStyles.WrapperRow>
  );
};

export default Home;
