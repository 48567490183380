import React from "react";
import { Col, message, Row } from "antd";
import * as CommonStyles from "@styles/CommonStyles.style";
import { License } from "@app/common/types";
import { useTranslation } from "react-i18next";

interface ILicensePricingCardListProps {
  license: License;
}

const LicensePricingCardList: React.FC<ILicensePricingCardListProps> = ({
  license,
}) => {
  const { t } = useTranslation();

  return (
    <CommonStyles.FullyCenteredRow>
      <Col span={24}>
        <CommonStyles.FullyCenteredRowWithoutMargin>
          <CommonStyles.Text>
            {t("pages.main.sections.pricing.userCountText", {
              message: license.userCount,
            })}
          </CommonStyles.Text>
        </CommonStyles.FullyCenteredRowWithoutMargin>

        <CommonStyles.FullyCenteredRowWithoutMargin>
          <CommonStyles.Text>
            {t("pages.main.sections.pricing.datasetCountText", {
              message: license.datasetCount,
            })}
          </CommonStyles.Text>
        </CommonStyles.FullyCenteredRowWithoutMargin>

        <CommonStyles.FullyCenteredRowWithoutMargin>
          <CommonStyles.Text>
            {t("pages.main.sections.pricing.dashboardCountText", {
              message: license.dashboardCount,
            })}
          </CommonStyles.Text>
        </CommonStyles.FullyCenteredRowWithoutMargin>

        <CommonStyles.FullyCenteredRowWithoutMargin>
          <CommonStyles.Text>
            {t("pages.main.sections.pricing.retentionText", {
              message: license.retention,
            })}
          </CommonStyles.Text>
        </CommonStyles.FullyCenteredRowWithoutMargin>
      </Col>
    </CommonStyles.FullyCenteredRow>
  );
};

export default LicensePricingCardList;
