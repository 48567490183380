import React, { useCallback } from "react";
import { Button, Col, Row } from "antd";
import { License } from "@common/types";
import { Currency, LicenseType } from "@common/enums";
import { LicensePricingCardList } from "../LicensePricingCardList";
import * as S from "./LicensePricing.style";
import * as CommonStyles from "@styles/CommonStyles.style";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@app/common/hooks/reduxHooks";

interface ILicensePricingCardProps {
  license: License;
}

const LicensePricingCard: React.FC<ILicensePricingCardProps> = ({
  license,
}) => {
  const { t } = useTranslation();
  const userAgent = useAppSelector((state) => state.userAgent.data);

  const getCurrencyAndPrice = useCallback(() => {
    switch (userAgent.currencyCode) {
      case "TRY":
        return {
          price: license.price.tr,
          type: Currency.TRY,
          symbol: "₺",
        };
      case "EUR":
        return {
          price: license.price.eu,
          type: Currency.EUR,
          symbol: "€",
        };
      case "USD":
      default:
        return {
          price: license.price.us,
          type: Currency.USD,
          symbol: "$",
        };
    }
  }, [userAgent, license]);

  const getLicenseInfo = useCallback(() => {
    switch (license.licenseType) {
      case LicenseType.LICENSE_FREE:
        return {
          title: "Free",
          description: t("pages.main.sections.pricing.description"),
          buttonText: t("pages.main.sections.pricing.freeLicenseText"),
          onClick: () => {
            window.open(
              `${process.env.REACT_APP_APPLICATION_PATH}/auth/sign-up`,
              "_blank"
            );
          },
        };
      case LicenseType.LICENSE_STARTER:
        return {
          title: "Starter",
          description: t("pages.main.sections.pricing.description"),
          buttonText: t("pages.main.sections.pricing.paidLicenseText"),
          onClick: () =>
            window.open(
              `${process.env.REACT_APP_APPLICATION_PATH}/auth/sign-up`,
              "_blank"
            ),
        };
      case LicenseType.LICENSE_PRO:
        return {
          title: "Pro",
          description: t("pages.main.sections.pricing.description"),
          buttonText: t("pages.main.sections.pricing.paidLicenseText"),
          onClick: () =>
            window.open(
              `${process.env.REACT_APP_APPLICATION_PATH}/auth/sign-up`,
              "_blank"
            ),
        };
      case LicenseType.LICENSE_ENTERPRISE:
        return {
          title: "Enterprise",
          description: t("pages.main.sections.pricing.description"),
          buttonText: t("pages.main.sections.pricing.paidLicenseText"),
          onClick: () =>
            window.open(
              `${process.env.REACT_APP_APPLICATION_PATH}/auth/sign-up`,
              "_blank"
            ),
        };
    }
  }, [license]);

  return (
    <S.LicensePricingCard>
      <Col span={24}>
        <CommonStyles.FullyCenteredRow>
          <CommonStyles.Title>{getLicenseInfo().title}</CommonStyles.Title>
        </CommonStyles.FullyCenteredRow>

        <CommonStyles.FullyCenteredRow>
          <Col>
            <S.Currency>{getCurrencyAndPrice().symbol}</S.Currency>
          </Col>
          <Col>
            <S.Price>{getCurrencyAndPrice().price}</S.Price>
          </Col>
          <Row>
            <Col>
              <CommonStyles.InfoText>{`/${t(
                "common.month"
              )}`}</CommonStyles.InfoText>
            </Col>
          </Row>
        </CommonStyles.FullyCenteredRow>

        <CommonStyles.FullyCenteredRow>
          <LicensePricingCardList license={license} />
        </CommonStyles.FullyCenteredRow>

        <CommonStyles.FullyCenteredRow>
          <Button
            type="primary"
            size="small"
            htmlType="button"
            onClick={() => getLicenseInfo().onClick()}
          >
            {getLicenseInfo().buttonText}
          </Button>
        </CommonStyles.FullyCenteredRow>
      </Col>
    </S.LicensePricingCard>
  );
};

export default LicensePricingCard;
