import styled from "styled-components";
import { Card } from "../Card";
import { Row, Typography } from "antd";
import { FONT_SIZE, FONT_WEIGHT } from "@common/constants";

export const LicensePricingCard = styled(Card)`
  min-width: 280px;
  width: 350px;
  margin: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  & .ant-card-body {
    padding: 1.25rem;
  }
`;

export const Currency = styled.p`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.bold};
`;

export const Price = styled(Typography.Title)`
  font-size: 15rem;
  font-weight: ${FONT_WEIGHT.extraBold};
  &.ant-typography {
    margin: 0;
  }
`;
