import styled from "styled-components";
import { FONT_SIZE } from "@common/constants";
import { Row, Typography } from "antd";

export const ContainerRow = styled(Row)`
  margin: 1.625rem 0;
  justify-content: center;
`;

export const ElementContainer = styled(Row)`
  justify-content: center;
  margin: 1rem 0;
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 0;

    font-size: ${FONT_SIZE.x4l};
  }
`;

export const Subtitle = styled(Typography.Text)`
  opacity: 0.6;
  font-weight: 600;
`;

export const DevicesImage = styled.img`
  width: 65%;
  height: auto;
`;
